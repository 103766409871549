import * as React from "react";
import { Fragment } from 'react';

import {
    List,
    Show,
    Filter,
    TabbedShowLayout,
    Tab,
    ReferenceManyField,
    Datagrid,
    TextField,
    ImageField,
    NumberField,
    UrlField,
    TextInput,
    BulkDeleteButton,
    NumberInput,
    BooleanField,
    DateField,
    Pagination

} from 'react-admin';





const PageFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="substring" alwaysOn />
        <NumberInput label="Min BCs" source="minBCs" min={0} />
        <NumberInput label="Max BCs" source="maxBCs" min={0} />
        {props.filteredits ? 
            <NumberInput label="Min Edits" source="minEditsCount" min={0} /> : null}
          {props.filteredits ?    
          <NumberInput label="Max Edits" source="maxEditsCount" min={0} /> :null}
    </Filter>
);







const MyPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;




export const PageList = props => {

    let possibleTitles = {
        '/processedPages': 'Processed pages',
        '/templatePages': 'Templates',
        '/pagesAwaitingProcessing': 'Pages awaiting processing',
        '/pagesToProcessLater':'Pages to process later',
        '/pagesWithNoNeedForProcessing':"Pages that don't need to be processed",
        '/pagesWithNoOwnBCs': 'Pages with no own BCs',
        '/pagesWithIssues': 'Pages with issues',
        '/pagesWithSmallIssues': 'Pages with small issues',
        '/pagesNeedingImages': 'Pages that need images',
        '/trashPages': 'Trash'
    
        
    }
    let title = possibleTitles[props.basePath]

    return (
        <List title={title} {...props} empty={false} perPage={20} filters={<PageFilter filteredits={props.filteredits}/>} pagination={<MyPagination/>} bulkActionButtons={false}>
            <Datagrid rowClick="show" >

                <TextField source="title" sortBy="title" />
                <NumberField source="editsCount" label="Edits" />
                <NumberField source="properBCs" label="BCs" />
                <UrlField source="url" />
            </Datagrid>
        </List>
    );
}

export const PageListWithEditsFilter = props => (
    <PageList {...props} filteredits="true" />
)




const BulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);


export const PageShow = (props) => {

    //  const basePath = props.basePath.replace('/', '')
    // const pages = useSelector(state => {

    //     const resourses = state.admin.resources
    //     const p = resourses[basePath]
    //     return p.data
    // })

    return (
        <Show {...props} title={'Page details'}>
            <TabbedShowLayout>
                <Tab label="Page">

                    <TextField source="title" addLabel={false} style={{ fontSize: 24, fontWeight: 'bold' }} />
                    <ImageField source="originalSummaryImageUrl" label="Original summary image"/>
                    <UrlField source="url" label="Link" />
                    <TextField source="website" />
                    <TextField source="id" />
                    <UrlField source="redirectTo" />
                    <TextField source="locale" />

                    <BooleanField source="hasIssues" label="Has issues" />
                    <BooleanField source="hasSmallIssues" label="Has small issues" />

                    <BooleanField source="needsImages" label="Images are needed" />

                    <NumberField source="properBCs" label="Number of BCs" />
                    <NumberField source="editsCount" label="Number of edits" />


                 


                    <p style={{ fontSize: 16, fontWeight: 'bold' }}>Edits:</p>
                    <div style={{ backgroundColor: 'gray', width: '100%', height: '1px' }}></div>

                    <ReferenceManyField reference="edits" target="id" addLabel={false}>
                        <Datagrid  rowStyle={(record) => {
                            return  record.isTemplate ? {backgroundColor:'lightGray'} : {}
                        }}>

                            <TextField source="string" />
                            <TextField source="target" />
                            <TextField source="method" />
                            <BooleanField source="notFound" label="Issues" TrueIcon={() => (<strong style={{color:'red'}}>!!</strong>)} FalseIcon={"span"} valueLabelTrue="issue"/>
                            <BooleanField source="isSus" label="Small issues" TrueIcon={() => (<strong style={{color:'red'}}>!</strong>)} FalseIcon={"span"}  valueLabelTrue="issue"/>
                            <TextField source="originalSubstitute" />
                            <TextField source="order" />

                        </Datagrid>
                    </ReferenceManyField>


                </Tab>



                <Tab label="Images">
                    <ReferenceManyField reference="imagesOnPages" target="id" addLabel={false}>

                        <Datagrid rowClick="show" >

                        <ImageField source="originalImageURL" label="Original image"/>
                        <TextField source="name"/>

                        </Datagrid>



                    </ReferenceManyField>

                </Tab>


            </TabbedShowLayout>







        </Show >
    );
}




// const TableCell = props => {
//     const { text, width } = props
//     return (
//         <div style={{ width: `${width}px` }}>
//             <span >{text}</span>
//         </div>
//     )
// }








export const EditsList = (props) => {

    return (<List {...props} target="id" bulkActionButtons={<BulkActionButtons />}>
        <Datagrid rowClick="edit" hasBulkActions={true}>

            <TextField source="string" />
            <TextField source="target" />
            <TextField source="method" />
            <TextField source="type" />
            <TextField source="order" />

        </Datagrid>

    </List>)
}

