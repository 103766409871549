
import './App.css';
import { Admin, Resource } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import { PageList, PageListWithEditsFilter, PageShow } from './pages'
import { ImageShow, ImageList } from './images'
import PagesIcon from '@material-ui/icons/Layers';
import Warning from '@material-ui/icons/Warning';
import Triangle from '@material-ui/icons/ChangeHistory';
import ImageSearch from '@material-ui/icons/ImageSearch';
import Agenda from '@material-ui/icons/ViewAgenda';
import Automatic from '@material-ui/icons/BrightnessAuto';
import Redirect from '@material-ui/icons/SubdirectoryArrowRight';
import Changes from '@material-ui/icons/GraphicEqOutlined';
import TrashIcon from '@material-ui/icons/DeleteForever';
import TemplateIcon from '@material-ui/icons/Subject';
import SuspiciousIcon from '@material-ui/icons/HelpOutline';
import ImageIcon from '@material-ui/icons/Image';


export const apiUrl = process.env.REACT_APP_DECAMILLENNIUM_SERVER_URL

const dataProvider = jsonServerProvider(apiUrl);
const App = () => <Admin dataProvider={dataProvider} >

  <Resource name="processedPages" list={PageListWithEditsFilter} show={PageShow} options={{ label: "Processed pages" }} icon={PagesIcon} />
  <Resource name="templatePages" list={PageListWithEditsFilter} show={PageShow} options={{ label: "Templates" }} icon={TemplateIcon} />
  <Resource name="pagesAwaitingProcessing" list={PageList} show={PageShow} options={{ label: "Awaiting processing" }} icon={Agenda} />
  <Resource name="pagesToProcessLater" list={PageList} show={PageShow} options={{ label: "Process later" }} icon={Agenda} />
  <Resource name="pagesWithNoNeedForProcessing" list={PageList} show={PageShow} options={{ label: "Processing not needed" }} icon={Agenda} />
  <Resource name="pagesWithNoOwnBCs" list={PageList} show={PageShow} options={{ label: "No own BCs" }} icon={Agenda} />
  
  <Resource name="pagesWithIssues" list={PageListWithEditsFilter} show={PageShow} options={{ label: "Pages with issues" }} icon={Warning} />
  <Resource name="pagesWithSmallIssues" list={PageListWithEditsFilter} show={PageShow} options={{ label: "Small issues" }} icon={Warning} />
  <Resource name="pagesNeedingImages" list={PageListWithEditsFilter} show={PageShow} options={{ label: "Pages needing images" }} icon={ImageSearch} />
  <Resource name="redirects" list={PageList} show={PageShow} options={{ label: "Redirects" }} icon={Redirect} />
  <Resource name="trashPages" list={PageList} show={PageShow} options={{ label: "Trash" }} icon={TrashIcon} />

  <Resource name="imagesOnPages" show={ImageShow}/>

  <Resource name="edits"/>

</Admin>;

export default App;
