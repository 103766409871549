import * as React from "react";
import {
    List,
    Show,
    Datagrid,
    TextField,
    SimpleForm,
    ImageField,
    Pagination
} from 'react-admin';

const MyPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;

export const ImageList = props => (
    <List {...props} perPage={20} pagination={<MyPagination/>}>
        <Datagrid rowClick="show">

            <ImageField source="originalImageURL" label="Original image"/>
            <TextField source="name"/>
            
        </Datagrid>
    </List>
);

export const ImageShow = (props) => {

    return (
        <Show {...props}>
            <SimpleForm toolbar={null}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="originalImageURL" />
                <ImageField source="originalImageURL" />
                <TextField source="substituteImageURL" />
                <ImageField source="substituteImageURL" />

            </SimpleForm>
        </Show>
    );
}